import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.array.filter.js";
import { UiAvatar, UiDropDown, UiList, UiListItem, UiTooltip } from '@frontend/ui-kit';
import { useI18n } from '~/plugins/i18n';
export default defineComponent({
  name: 'LocaleSelector',
  components: {
    UiList: UiList,
    UiListItem: UiListItem,
    UiAvatar: UiAvatar,
    UiTooltip: UiTooltip,
    UiDropDown: UiDropDown
  },
  setup: function setup() {
    var i18n = useI18n();
    var locales = computed(function () {
      return i18n.locales;
    });
    var activeLocale = computed(function () {
      var locale = locales.value.find(function (i) {
        return i.code === i18n.locale;
      });
      localStorage.setItem('userLocale', locale === null || locale === void 0 ? void 0 : locale.code);
      return locale;
    });
    function setLocale(code, close) {
      i18n.setLocale(code);
      close();
    }
    var listOfLocales = computed(function () {
      return locales.value.filter(function (_ref) {
        var code = _ref.code;
        return code !== activeLocale.value.code && code !== 'dev';
      }).sort(function (a, b) {
        return a.code.localeCompare(b.code);
      });
    });
    return {
      listOfLocales: listOfLocales,
      activeLocale: activeLocale,
      setLocale: setLocale,
      locale: 'nav.language'
    };
  }
});