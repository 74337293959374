import "core-js/modules/es.array.concat.js";
import { UiBreadcrumbs, UiIconButton, UiStyles, UiIconName } from '@frontend/ui-kit';
import { storeToRefs } from 'pinia';
import PageTitle from '~/components/layout/PageTitle.vue';
import { UiTeleportTarget } from '~/components/ui-kit/UiTeleport';
import { UiTeleportNames } from '~/components/ui-kit/UiTeleport/UiTeleportNames';
import useAppStore from '~/stores/appStore';
export default defineComponent({
  components: {
    UiBreadcrumbs: UiBreadcrumbs,
    PageTitle: PageTitle,
    UiTeleportTarget: UiTeleportTarget,
    UiIconButton: UiIconButton
  },
  setup: function setup() {
    var _storeToRefs = storeToRefs(useAppStore()),
      backLink = _storeToRefs.backLink,
      breadcrumbs = _storeToRefs.breadcrumbs,
      titleShadow = _storeToRefs.titleShadow,
      headerTeleportId = _storeToRefs.headerTeleportId,
      titleLocale = _storeToRefs.title,
      mobileHeaderBorder = _storeToRefs.mobileHeaderBorder;
    var teleportLeftName = computed(function () {
      return "".concat(UiTeleportNames.Header.Left, "-").concat(headerTeleportId.value);
    });
    var teleportRightName = computed(function () {
      return "".concat(UiTeleportNames.Header.Right, "-").concat(headerTeleportId.value);
    });
    var teleportBottomName = computed(function () {
      return "".concat(UiTeleportNames.Header.Bottom, "-").concat(headerTeleportId.value);
    });
    return {
      titleLocale: titleLocale,
      breadcrumbs: breadcrumbs,
      teleportLeftName: teleportLeftName,
      teleportRightName: teleportRightName,
      teleportBottomName: teleportBottomName,
      titleShadow: titleShadow,
      UiStyles: UiStyles,
      UiIconName: UiIconName,
      backLink: backLink,
      mobileHeaderBorder: mobileHeaderBorder
    };
  }
});