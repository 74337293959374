import { UiIconName, UiIconButton, toArray } from '@frontend/ui-kit';
import useNotificationsStore from '~/stores/notificationsStore';
export default defineComponent({
  name: 'NotificationsToasts',
  components: {
    UiIconButton: UiIconButton
  },
  setup: function setup() {
    var notificationsStore = useNotificationsStore();
    var _toRefs = toRefs(notificationsStore),
      notifications = _toRefs.notifications;
    function closeNotification(id) {
      notificationsStore.deleteNotification(id);
    }
    return {
      toArray: toArray,
      UiIconName: UiIconName,
      notifications: notifications,
      closeNotification: closeNotification
    };
  }
});