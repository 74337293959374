import "core-js/modules/web.timers.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.splice.js";
import { defineStore } from 'pinia';
export var NotificationTypes;
(function (NotificationTypes) {
  NotificationTypes["Success"] = "success";
  NotificationTypes["Error"] = "error";
})(NotificationTypes || (NotificationTypes = {}));
function addId(notification) {
  if (!notification.id) {
    notification.id = new Date().toISOString();
  }
}
var useNotificationsStore = defineStore('notifications', function () {
  var notifications = ref([]);
  function pushNotification(notification) {
    if (notification.message) {
      addId(notification);
      notifications.value.unshift(notification);
      if (notification.closeAfter > 0) {
        setTimeout(function () {
          return deleteNotification(notification.id);
        }, notification.closeAfter);
      }
    }
  }
  function deleteNotification(id) {
    var index = notifications.value.findIndex(function (el) {
      return el.id === id;
    });
    if (index > -1) {
      notifications.value.splice(index, 1);
    }
  }
  function addSuccess(message) {
    var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
      _ref$tc = _ref.tc,
      tc = _ref$tc === void 0 ? false : _ref$tc,
      _ref$closeAfter = _ref.closeAfter,
      closeAfter = _ref$closeAfter === void 0 ? 3000 : _ref$closeAfter;
    pushNotification({
      type: NotificationTypes.Success,
      tc: tc,
      message: message,
      closeAfter: closeAfter
    });
  }
  function addError(message) {
    var _ref2 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
      _ref2$tc = _ref2.tc,
      tc = _ref2$tc === void 0 ? false : _ref2$tc,
      _ref2$closeAfter = _ref2.closeAfter,
      closeAfter = _ref2$closeAfter === void 0 ? 5000 : _ref2$closeAfter;
    pushNotification({
      type: NotificationTypes.Error,
      tc: tc,
      message: message,
      closeAfter: closeAfter
    });
  }
  return {
    notifications: notifications,
    deleteNotification: deleteNotification,
    addSuccess: addSuccess,
    addError: addError
  };
}, {
  // @ts-ignore
  persistedState: {
    includePaths: []
  }
});
export default useNotificationsStore;