import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import { defineStore } from 'pinia';
import useRecentPagesStore from '~/stores/recentPagesStore';
function useLoaderMessage() {
  var loadingMessage = ref('');
  function setLoadingMessage(message) {
    loadingMessage.value = message;
  }
  function resetLoadingMessage() {
    loadingMessage.value = '';
  }
  return {
    loadingMessage: loadingMessage,
    setLoadingMessage: setLoadingMessage,
    resetLoadingMessage: resetLoadingMessage
  };
}
var useAppStore = defineStore('app', function () {
  var loading = ref(false);
  var fixedHeader = ref(false);
  var fullPage = ref({
    desktop: false,
    mobile: false
  });
  var title = ref('Ailet');
  var defaultPadding = ref(true);
  var backLink = ref('');
  var breadcrumbs = ref([]);
  var titleShadow = ref(false);
  var darkBackground = ref(false);
  var headerTeleportId = ref('');
  var mobileHeaderBorder = ref(false);
  function setLoading(value) {
    loading.value = value;
  }
  var recentPages = useRecentPagesStore();
  function setTitle(value) {
    title.value = value;
    recentPages.updateTitle(value);
  }
  function changeBreadcrumb(payload) {
    if (breadcrumbs.value[payload.pos]) {
      breadcrumbs.value[payload.pos] = payload.element;
      breadcrumbs.value = _toConsumableArray(breadcrumbs.value);
    }
  }
  var _useLoaderMessage = useLoaderMessage(),
    loadingMessage = _useLoaderMessage.loadingMessage,
    setLoadingMessage = _useLoaderMessage.setLoadingMessage,
    resetLoadingMessage = _useLoaderMessage.resetLoadingMessage;
  var isLoading = computed(function () {
    return Boolean(loading.value || loadingMessage.value);
  });
  return {
    loadingMessage: loadingMessage,
    setLoadingMessage: setLoadingMessage,
    resetLoadingMessage: resetLoadingMessage,
    loading: loading,
    isLoading: isLoading,
    fixedHeader: fixedHeader,
    fullPage: fullPage,
    title: title,
    defaultPadding: defaultPadding,
    backLink: backLink,
    breadcrumbs: breadcrumbs,
    titleShadow: titleShadow,
    darkBackground: darkBackground,
    headerTeleportId: headerTeleportId,
    setLoading: setLoading,
    setTitle: setTitle,
    changeBreadcrumb: changeBreadcrumb,
    mobileHeaderBorder: mobileHeaderBorder
  };
});
export default useAppStore;