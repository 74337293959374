import { defineStore } from 'pinia';
var DEFAULT_UPLOAD_SPEED = 1025 * 150;
var useStatsStore = defineStore('stats', function () {
  var averageInternetSpeed = ref(0);
  var uploads = ref(0);
  function saveUpload(size, time) {
    uploads.value += 1;
    var speed = size / time;
    averageInternetSpeed.value = (uploads.value - 1) / uploads.value * averageInternetSpeed.value + speed / uploads.value;
  }
  function getEstimatedUploadTime(size) {
    return size / (averageInternetSpeed.value || DEFAULT_UPLOAD_SPEED);
  }
  return {
    averageInternetSpeed: averageInternetSpeed,
    uploads: uploads,
    saveUpload: saveUpload,
    getEstimatedUploadTime: getEstimatedUploadTime
  };
});
export default useStatsStore;