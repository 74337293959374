import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.join.js";
import { intervalToDuration } from 'date-fns';
import { UiList, UiListMenuItem } from '@frontend/ui-kit';
import { useI18n } from '~/plugins/i18n';
import useRecentPagesStore from '~/stores/recentPagesStore';
export default defineComponent({
  components: {
    UiListMenuItem: UiListMenuItem,
    UiList: UiList
  },
  setup: function setup() {
    var i18n = useI18n();
    var resentPagesStore = useRecentPagesStore();
    var recentPages = computed(function () {
      return resentPagesStore.items;
    });
    function getLabel(name, route) {
      if (name) return i18n.t(name);
      if (route.includes('/release')) return i18n.t('global.whatsnew');
      if (route.includes('/docs/')) return i18n.t('global.support');
      return i18n.t(route);
    }
    var items = computed(function () {
      return recentPages.value.map(function (el) {
        var _el$breadcrumbs;
        var label = getLabel(el.name, el.route);
        var link = el.route;
        var breadcrumbs = '';
        if (((_el$breadcrumbs = el.breadcrumbs) === null || _el$breadcrumbs === void 0 ? void 0 : _el$breadcrumbs.length) > 1) {
          var formatedBreadcrumbs = _toConsumableArray(el.breadcrumbs);
          formatedBreadcrumbs.pop();
          breadcrumbs = "".concat(i18n.t('global.main'), "/").concat(formatedBreadcrumbs.map(function (el) {
            return i18n.t(el.name);
          }).join('/'));
        }
        var timeInterval = intervalToDuration({
          start: new Date(el.timestamp),
          end: new Date()
        });
        var timeTitle = '';
        if (timeInterval.years) {
          timeTitle = i18n.tc('global.time.declensions.year', timeInterval.years);
        } else if (timeInterval.months) {
          timeTitle = i18n.tc('global.time.declensions.month', timeInterval.months);
        } else if (timeInterval.weeks) {
          timeTitle = i18n.tc('global.time.declensions.week', timeInterval.weeks);
        } else if (timeInterval.days) {
          timeTitle = i18n.tc('global.time.declensions.day', timeInterval.days);
        } else if (timeInterval.hours) {
          timeTitle = i18n.tc('global.time.declensions.hour', timeInterval.hours);
        } else if (timeInterval.minutes) {
          timeTitle = i18n.tc('global.time.declensions.minute', timeInterval.minutes);
        }
        var timestamp = "".concat(timeTitle || i18n.t('global.time.less_than_a_minute'), " ").concat(i18n.t('global.time.ago'));
        var subLabel = breadcrumbs ? "".concat(timestamp, " <br> ").concat(breadcrumbs) : timestamp;
        return {
          label: label,
          href: link,
          to: link.includes('old') ? '' : link,
          subLabel: subLabel,
          timestamp: timestamp,
          breadcrumbs: breadcrumbs
        };
      });
    });
    return {
      items: items
    };
  }
});