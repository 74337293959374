import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import { UiAvatar, UiIconName, UiIconTemplate, UiTooltip } from '@frontend/ui-kit';
import { useNuxt2Meta } from '@nuxt/bridge/dist/runtime';
import useUserStore from '~/stores/userStore';
export default defineComponent({
  components: {
    UiAvatar: UiAvatar,
    UiTooltip: UiTooltip,
    UiIconTemplate: UiIconTemplate
  },
  setup: function setup() {
    var _useUserStore = useUserStore(),
      user = _useUserStore.user;
    var intercomAppId = useRuntimeConfig().public.INTERCOM_APP_ID;

    // Number of unread messages
    var unreadCount = ref(0);
    function addOnUnreadCountChangeListener() {
      window.Intercom('onUnreadCountChange', function (newCount) {
        unreadCount.value = newCount;
      });
    }

    // Is the intercom window open or not
    var isOpen = ref(false);
    function addOpenHideListeners() {
      window.Intercom('onShow', function () {
        isOpen.value = true;
      });
      window.Intercom('onHide', function () {
        isOpen.value = false;
      });
    }
    useNuxt2Meta({
      script: [{
        hid: 'stripe',
        src: 'https://widget.intercom.io/widget/' + intercomAppId,
        defer: true,
        // Changed after script load
        callback: function callback() {
          addOnUnreadCountChangeListener();
          addOpenHideListeners();
        }
      }]
    });
    var DEFAULT_SETTINGS = {
      app_id: intercomAppId,
      Portal: location.origin,
      custom_launcher_selector: '#intercom_support',
      hide_default_launcher: true,
      horizontal_padding: 24
    };
    onMounted(function () {
      window.intercomSettings = DEFAULT_SETTINGS;
      if (user) {
        var username = user.username,
          email = user.email,
          id = user.id,
          role = user.role,
          login = user.login;
        window.intercomSettings = _objectSpread(_objectSpread({}, DEFAULT_SETTINGS), {}, {
          name: username,
          email: email,
          'User ID': id,
          Role: role,
          Login: login
        });
      }
    });
    return {
      isOpen: isOpen,
      unreadCount: unreadCount,
      locale: 'nav.message',
      UiIconName: UiIconName
    };
  },
  head: {}
});