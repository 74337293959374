import { defineStore } from 'pinia';
import { useI18n } from '~/plugins/i18n';
var useDevStore = defineStore('dev', function () {
  var i18n = useI18n();
  var dev = reactive({
    locale: i18n.locale,
    spa: false,
    stitchingF: true,
    stitchingB: false,
    // Visits
    realogramLine: undefined,
    realogramFaceId: false,
    // Mobile
    mobileSchema: false,
    // Pages
    massRecount: false
  });

  /*************************************************
   * Locale
   *************************************************/
  watch(function () {
    return dev.locale;
  }, function () {
    i18n.setLocale(dev.locale);
  });
  watch(function () {
    return i18n.locale;
  }, function () {
    dev.locale = i18n.locale;
  });
  return {
    dev: dev
  };
});
export default useDevStore;