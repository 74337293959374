import { UiAvatar, UiDropDown, UiIconName, UiIconTemplate, UiList, UiListItem, UiListMenuItem, UiTooltip } from '@frontend/ui-kit';
import useUserStore from '~/stores/userStore';
export default defineComponent({
  components: {
    UiListMenuItem: UiListMenuItem,
    UiListItem: UiListItem,
    UiList: UiList,
    UiDropDown: UiDropDown,
    UiAvatar: UiAvatar,
    UiTooltip: UiTooltip,
    UiIconTemplate: UiIconTemplate
  },
  setup: function setup() {
    var _useUserStore = useUserStore(),
      logout = _useUserStore.logout;
    var menu = [{
      onClick: function onClick() {},
      href: '/old/profile',
      locale: 'nav.profile'
    }, {
      onClick: logout,
      locale: 'nav.logout'
    }];
    return {
      menu: menu,
      locale: 'nav.profile',
      UiIconName: UiIconName
    };
  }
});