import _typeof from "@babel/runtime/helpers/esm/typeof";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.function.name.js";
import { useRoute } from 'vue-router/composables';
import useAppStore from '~/stores/appStore';
import useRecentPagesStore from '~/stores/recentPagesStore';
export function getBackLink(routePath) {
  var path = routePath.split('/');
  if (!path.pop()) {
    path.pop();
  }
  return path.join('/');
}
function getFullPageOption(value) {
  if (value === undefined) return {
    desktop: false,
    mobile: false
  };
  if (_typeof(value) === 'object') return value;
  return {
    desktop: value,
    mobile: value
  };
}
export default function usePageOptions(options) {
  var appStore = useAppStore();
  var route = useRoute();
  var resentPagesStore = useRecentPagesStore();
  var _toRefs = toRefs(appStore),
    title = _toRefs.title,
    defaultPadding = _toRefs.defaultPadding,
    fixedHeader = _toRefs.fixedHeader,
    fullPage = _toRefs.fullPage,
    backLink = _toRefs.backLink,
    breadcrumbs = _toRefs.breadcrumbs,
    titleShadow = _toRefs.titleShadow,
    darkBackground = _toRefs.darkBackground,
    headerTeleportId = _toRefs.headerTeleportId,
    mobileHeaderBorder = _toRefs.mobileHeaderBorder;
  onActivated(function () {
    title.value = options.title;
    defaultPadding.value = options.defaultPadding === undefined ? true : options.defaultPadding;
    fixedHeader.value = options.fixedHeader === undefined ? false : options.fixedHeader;
    fullPage.value = getFullPageOption(options.fullPage);
    breadcrumbs.value = options.breadcrumbs || [];
    titleShadow.value = options.titleShadow || false;
    darkBackground.value = options.darkBackground || false;
    headerTeleportId.value = options.teleportId || route.name || '';
    var showBack = options.showBack === undefined ? true : options.showBack;
    backLink.value = showBack ? options.backLink || getBackLink(route.path) : '';
    mobileHeaderBorder.value = Boolean(options.mobileHeaderBorder);
    resentPagesStore.addItem({
      name: title.value,
      route: route.path,
      breadcrumbs: appStore.breadcrumbs,
      timestamp: new Date()
    });
  });
  watch(function () {
    return options.title;
  }, function () {
    title.value = options.title;
  });
  return {
    options: options,
    backLink: backLink
  };
}