import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
import { defineStore } from 'pinia';
var useRecentPagesStore = defineStore('recentPages', function () {
  var items = ref([]);
  function addItem(item) {
    var newItems = items.value.filter(function (el) {
      return el.route !== item.route;
    });
    if (newItems.length >= 10) {
      newItems.length = 9;
    }
    newItems.unshift(item);
    items.value = newItems;
  }
  function updateTitle(title) {
    if (items.value[0]) {
      items.value[0].name = title;
    }
  }
  function clear() {
    items.value = [];
  }
  return {
    items: items,
    addItem: addItem,
    updateTitle: updateTitle,
    clear: clear
  };
});
export default useRecentPagesStore;